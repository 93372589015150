<template>
  <div>
    <BaseSection
      :id="section.key"
      :is-visible="isSectionVisible"
      :is-modified="false"
      :section="section"
    >
      <template #section>
        <v-file-input
            class="files-input"
            show-size
            chips
            :disabled="fileInProcess || FIELDS_DISABLED"
            :loading="fileInProcess"
            label="Final Statement of advice"
            v-model="statementFile.file"
        />
        <v-card
            class="my-2"
            elevation="0"
            v-show="isUploadedFilesExist"
            outlined
            tile
          >
            <v-card-title>
              Files to upload
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template #default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      File Name
                    </th>
                    <th class="text-left">
                      File Description
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(file, fileIdx) in uploadedFiles"
                    :key="fileIdx"
                  >
                    <td>{{ file.file.name }}</td>
                    <td>
                      <v-text-field
                        class="file-description"
                        type="text"
                        disabled
                        v-model="file.description"
                      ></v-text-field>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="!fileInProcess"
                color="primary"
                @click.prevent="uploadFiles"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
          <div class="files">
            <v-subheader>YOUR FILES</v-subheader>
            <div class="files-table">
              <div class="files-table__header">
                <span class="files-table__label text-center col-3">File Name</span>
                <span class="files-table__label text-center col-3">File Description</span>
                <span class="files-table__label text-center col-2">Size</span>
                <span class="files-table__label text-center col-2">Created At</span>
                <span class="files-table__label text-center col-1"></span>
                <span class="files-table__label text-center col-1"></span>
              </div>

              <div class="files-table__content">
                <div
                  v-for="(file, fileIdx) in sortedStoredFiles"
                  :key="fileIdx"
                  class="files-table__item"
                >
                  <div class="col-3">{{ file.filename }}</div>
                  <div class="col-3">
                    <v-text-field
                      class="without-indents file-description"
                      disabled
                      v-model="file.description"
                      @blur="fileDescriptionEdit(file)"
                      type="text"
                    ></v-text-field>
                  </div>
                  <div class="col-2">{{ file.size | formatBytes }}</div>
                  <div class="col-2">{{ file.timeCreated }}</div>
                  <div class="col-1">
                    <v-btn
                      :disabled="fileInProcess"
                      @click.prevent="download(file)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                  <div class="col-1">
                    <v-btn
                      icon
                      :disabled="fileInProcess"
                      @click.prevent="moveToArchive(file)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    </BaseSection>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
// eslint-disable-next-line import/named
import {
  FILE_STATUS,
  uploadToComplianceFile as uploadFileToStorage,
  downloadFile,
  saveOrderFile,
  getComplianceFileByApplicationId,
  archiveFile,
} from '@/services/firebase';

export default {
  name: 'FileManager',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      storedFiles: [],
      fileInProcess: false,
      filesCount: 0,
      statementFile: {
        file: null,
        description: 'Final Statement of advice',
      },
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION_ID',
      'FIELDS_DISABLED',
    ]),

    storedFilesDraggable: {
      get() {
        return this.sortedStoredFiles;
      },

      set(sortedFiles) {
        const saveDelay = 1000;

        const setNewOrderInFile = (file, idx) => {
          const orderFile = { ...file };
          orderFile.order = idx;

          return orderFile;
        };

        const newSortedFiles = sortedFiles.map(setNewOrderInFile);

        this.storedFiles = [...newSortedFiles];

        this.needSaveFilesOrder = true;

        setTimeout(this.saveFilesOrder(this.storedFiles), saveDelay);
      },
    },

    sortedStoredFiles() {
      const files = this.sortStoredFiles();

      return files;
    },

    isstatementFileExist() {
      const isUploadedFileExist = Boolean(this.statementFile.file);

      return isUploadedFileExist;
    },

    uploadedFiles() {
      const files = [];
      if (this.isstatementFileExist) {
        files.push(this.statementFile);
      }
      return files;
    },

    isUploadedFilesExist() {
      const isUploadedFilesExist = Boolean(this.uploadedFiles?.length > 0);

      return isUploadedFilesExist;
    },
  },

  async created() {
    await this.checkIsSectionVisible();
    await this.getFiles();
    this.clearUploadedFiles();

    this.$emit('componentReady');
  },

  methods: {
    saveFilesOrder(files) {
      const isNotNeedSaveFilesOrder = Boolean(!this.needSaveFilesOrder);
      if (isNotNeedSaveFilesOrder) {
        return;
      }

      files.forEach((item) => {
        saveOrderFile(item);
      });
    },

    sortStoredFiles() {
      const sortFiles = (firstFile, secondFile) => firstFile.order - secondFile.order;

      const files = [...this.storedFiles];
      files.sort(sortFiles);

      return files;
    },

    async uploadFiles() {
      this.fileInProcess = true;

      const uploadFile = async (file, idx) => {
        const fileData = file.file;

        const fileMetadata = {
          customMetadata: {
            filename: fileData.name,
            description: file.description ?? '',
            status: FILE_STATUS.active,
            order: idx + this.filesCount,
          },
        };

        const filePayload = {
          file: fileData,
          metadata: fileMetadata,
          adviserId: this.userId,
        };

        const fileResponse = await uploadFileToStorage(filePayload);

        this.setFileToStoredFiles(fileResponse.metadata);
      };

      const stored = this.sortedStoredFiles;

      if (stored.length) {
        for (let i = 0; i < this.uploadedFiles.length; i += 1) {
          const uploadedFileDescription = this.uploadedFiles[i].description;
          const remove = stored.filter((item) => item.description === uploadedFileDescription);
          if (remove.length) {
            await this.moveToArchive(remove[0]);
          }
        }
      }

      for (let i = 0; i < this.uploadedFiles.length; i += 1) {
        await uploadFile(this.uploadedFiles[i], i);
      }

      this.clearUploadedFiles();

      this.fileInProcess = false;
    },

    removeFromStoredFiles(removedFile) {
      const findFileByPath = (file) => file.path === removedFile.path;

      const fileIndex = this.storedFiles.findIndex(findFileByPath);

      this.storedFiles.splice(fileIndex, 1);
    },

    async getFiles() {
      const files = await getComplianceFileByApplicationId(this.userId);

      const setStoredFile = (file) => {
        file.getMetadata().then(this.setFileToStoredFiles);
      };

      this.storedFiles = [];
      files.items.forEach(setStoredFile);
    },

    clearUploadedFiles() {
      this.statementFile.file = null;
    },

    async moveToArchive(file) {
      this.fileInProcess = true;
      await archiveFile(file);

      this.removeFromStoredFiles(file);
      this.fileInProcess = false;
    },

    async download(file) {
      const fileDownload = downloadFile(file.path);
      const url = await fileDownload.getDownloadURL();

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('target', '_blank');
      downloadLink.setAttribute('download', file.filename);

      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    changeFileOrder({ relatedContext, draggedContext }) {
      this.needSaveFilesOrder = false;

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      const isNotRelatedElement = Boolean(!relatedElement || !relatedElement.fixed);
      const isFileOrderChanged = Boolean(isNotRelatedElement && !draggedElement.fixed);

      return isFileOrderChanged;
    },

    setFileToStoredFiles(fileMetadata) {
      const fileName = fileMetadata?.customMetadata?.filename ?? fileMetadata?.filename;
      const isFileNotActive = Boolean(fileMetadata?.customMetadata?.status !== FILE_STATUS.active);

      if (isFileNotActive) {
        return;
      }

      const fileData = {
        path: fileMetadata.fullPath,
        size: fileMetadata.size,
        contentType: fileMetadata.contentType,
        timeCreated: fileMetadata.timeCreated,
        filename: fileName,
        description: fileMetadata?.customMetadata?.description ?? '-',
        order: fileMetadata?.customMetadata?.order,
      };

      this.storedFiles.push(fileData);
    },
  },
};
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #bbb;
}

.files-input {
  padding-left: 16px;
  padding-right: 16px;
}

.flip-list-move {
  transition: transform 0.3s;
}

.without-indents {
  margin: 0;
  padding: 0;
}

.no-move {
  transition: transform 0s;
}

.list-group-item {
  cursor: move;
}

.files {
  display: flex;
  flex-direction: column;

  &-table {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}
</style>
