var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": false,
      "section": _vm.section
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-file-input', {
          staticClass: "files-input",
          attrs: {
            "show-size": "",
            "chips": "",
            "disabled": _vm.fileInProcess || _vm.FIELDS_DISABLED,
            "loading": _vm.fileInProcess,
            "label": "Final Statement of advice"
          },
          model: {
            value: _vm.statementFile.file,
            callback: function callback($$v) {
              _vm.$set(_vm.statementFile, "file", $$v);
            },
            expression: "statementFile.file"
          }
        }), _c('v-card', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isUploadedFilesExist,
            expression: "isUploadedFilesExist"
          }],
          staticClass: "my-2",
          attrs: {
            "elevation": "0",
            "outlined": "",
            "tile": ""
          }
        }, [_c('v-card-title', [_vm._v(" Files to upload ")]), _c('v-card-text', [_c('v-simple-table', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left"
              }, [_vm._v(" File Name ")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v(" File Description ")])])]), _c('tbody', _vm._l(_vm.uploadedFiles, function (file, fileIdx) {
                return _c('tr', {
                  key: fileIdx
                }, [_c('td', [_vm._v(_vm._s(file.file.name))]), _c('td', [_c('v-text-field', {
                  staticClass: "file-description",
                  attrs: {
                    "type": "text",
                    "disabled": ""
                  },
                  model: {
                    value: file.description,
                    callback: function callback($$v) {
                      _vm.$set(file, "description", $$v);
                    },
                    expression: "file.description"
                  }
                })], 1)]);
              }), 0)];
            },
            proxy: true
          }])
        })], 1), _c('v-card-actions', [!_vm.fileInProcess ? _c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.uploadFiles.apply(null, arguments);
            }
          }
        }, [_vm._v(" Upload ")]) : _vm._e()], 1)], 1), _c('div', {
          staticClass: "files"
        }, [_c('v-subheader', [_vm._v("YOUR FILES")]), _c('div', {
          staticClass: "files-table"
        }, [_c('div', {
          staticClass: "files-table__header"
        }, [_c('span', {
          staticClass: "files-table__label text-center col-3"
        }, [_vm._v("File Name")]), _c('span', {
          staticClass: "files-table__label text-center col-3"
        }, [_vm._v("File Description")]), _c('span', {
          staticClass: "files-table__label text-center col-2"
        }, [_vm._v("Size")]), _c('span', {
          staticClass: "files-table__label text-center col-2"
        }, [_vm._v("Created At")]), _c('span', {
          staticClass: "files-table__label text-center col-1"
        }), _c('span', {
          staticClass: "files-table__label text-center col-1"
        })]), _c('div', {
          staticClass: "files-table__content"
        }, _vm._l(_vm.sortedStoredFiles, function (file, fileIdx) {
          return _c('div', {
            key: fileIdx,
            staticClass: "files-table__item"
          }, [_c('div', {
            staticClass: "col-3"
          }, [_vm._v(_vm._s(file.filename))]), _c('div', {
            staticClass: "col-3"
          }, [_c('v-text-field', {
            staticClass: "without-indents file-description",
            attrs: {
              "disabled": "",
              "type": "text"
            },
            on: {
              "blur": function blur($event) {
                return _vm.fileDescriptionEdit(file);
              }
            },
            model: {
              value: file.description,
              callback: function callback($$v) {
                _vm.$set(file, "description", $$v);
              },
              expression: "file.description"
            }
          })], 1), _c('div', {
            staticClass: "col-2"
          }, [_vm._v(_vm._s(_vm._f("formatBytes")(file.size)))]), _c('div', {
            staticClass: "col-2"
          }, [_vm._v(_vm._s(file.timeCreated))]), _c('div', {
            staticClass: "col-1"
          }, [_c('v-btn', {
            attrs: {
              "disabled": _vm.fileInProcess
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.download(file);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1), _c('div', {
            staticClass: "col-1"
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "disabled": _vm.fileInProcess
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.moveToArchive(file);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete-outline")])], 1)], 1)]);
        }), 0)])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }